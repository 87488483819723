import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import * as commonConst from '../../components/common/Common';
import { useNavigate } from "react-router-dom"

const Topbar = () => {
  const navigate = useNavigate();
  const logout = () => {
    let text = "Are you sure want to logout?"
    const res = window.confirm(text)
    if (res == true) {
      commonConst.deleteCookie("token")
      navigate("/")
    } 
  }


  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,

        background: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        height: "68px"
      }}
    >
      <Toolbar sx={{ width: '99%' }}>
        <Typography variant="h6">
          Patient Managment System
        </Typography>
        <Tooltip sx={{ right: '0', position: 'absolute' }} title={"Logout"}>
          <IconButton onClick={logout}>
            <PowerSettingsNewIcon sx={{ color: 'white' }}></PowerSettingsNewIcon>
          </IconButton >
        </Tooltip>
      </Toolbar>

    </AppBar>
  );
};

export default Topbar;
import { RouteType } from "./config";
import AppointmentIcon from '../assets/icons/AppointmentIcon'
import * as commonConst from '../components/common/Common';
import PatientPage from "../pages/patient/PatientPage"
import SitePage from "../pages/site/SitePage";
import ConsultationPage from "../pages/consultation/ConsultationPage";
import ServicesPage from "../pages/services/ServicesPage";
import EquipmentPurchasePage from "../pages/equipment/EquipmentPurchasePage";
import GroupIcon from '@mui/icons-material/Group';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import UserPage from "../pages/user/UserPage";

const isAdmin = () =>{

  const token = commonConst.getCookie("token")
  if(token){
    const payload = commonConst.parseJwt(token)
    if(payload){
      return payload.isAdmin
    }
  }
  return false
}

const getRoleBasedUserOption = () =>{

    return isAdmin() ? "users" :""
}

const appRoutes: RouteType[] = [

  {
    path: "patients",
    element: <PatientPage />,
    state: "patients",
    sidebarProps: {
      displayText: "Patient",
      icon: <PersonAddIcon />
    }
  },
  {
    path: "consultation",
    element: <ConsultationPage />,
    state: "consultation",
    sidebarProps: {
      displayText: "Consultation",
      icon: <AppointmentIcon />
    },
   
  },
  {
    path: "equpiment_purchases",
    element: <EquipmentPurchasePage />,
    state: "equipment",
    sidebarProps: {
      displayText: "Equipment Purchases",
      icon: <ShoppingCartIcon />
    },
    
  },
  {
    path:  getRoleBasedUserOption(),
    element: <UserPage />,
    state: "users",
    sidebarProps: {
      displayText: "Users",
      icon: <GroupIcon />
    }
  },
  {
    path: "sites",
    element: <SitePage />,
    state: "sites",
    sidebarProps: {
      displayText: "Sites",
      icon: <LocationCityIcon />
    }
  },
  {
    path: "services",
    element: <ServicesPage />,
    state: "services",
    sidebarProps: {
      displayText: "Services",
      icon: <MedicalServicesIcon />
    }
  }
];

export default appRoutes;
import React, { useState,useEffect  } from 'react';
import MUIDataTable from 'mui-datatables'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { FilterType} from 'mui-datatables'

import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { ServicesForm } from './ServicesForm';
import { DBService } from '../../components/common/DBServices';
import DeleteIcon from '@mui/icons-material/Delete';

   const options = {
     filterType: 'multiselect' as FilterType,
     selectableRowsHeader: false,
     selectableRowsHideCheckboxes: true
   };

   const addBtnSx = {
    backgroundColor: colorConfigs.themeColor,  borderRadius:'25px', float:"right", height: 'fit-content',
    "&:hover": {
      backgroundColor: colorConfigs.themeColor
    }
  };

 


const ServicesPage = () => {
  
  const handleEdit = (index: number) =>{
    const editData = data[index]
    setEditData(editData)
    isOpenInEditMode(true)
  }

  const handleDelete = (index: number) =>{
    const editData: any = data[index]
    
    const res = window.confirm("Are you sure to delete "+editData.name)
    if(res){
      const data = { _id: editData._id}
      Nw.Post(commonConst.DELETE_SERVICE,data).then(response => {
        if (response.data.code === 200) {

            fetchData()
            alert("Deleted Successfully")
            const db = new DBService()
            db.delete("services",editData._id)
        }else{
          alert("Something went wrong! "+response.data.code)
        }
      }).catch(error => {
          alert("Something went wrong!")
      })
    }

  }

  const mapOldServiceTypeToUpdatedString = (serviceType: string) => {

      switch(serviceType){

        case "Service":
          return "Rehabilitation Services"

        case "Lab Test":
          return "Diagnostic Tests"

          default:
            return serviceType
      }
  }

  const columns = [
   
    {
      name: "name",
      label: "Sevice Name",
      options: {
       filter: false,
       sort: false,
      }
     },
     {
      name: "service_type",
      label: "Type",
      options: {
       filter: true,
       sort: false,
       customBodyRender: (value: any) => {
        return (
          
           mapOldServiceTypeToUpdatedString(value)

        );
      }
      }
     },
    
    {
     name: "amount",
     label: "Price (INR) ",
     options: {
      filter: true,
      sort: false,
     }
    },
     {
      name: "Action",
      label: "Action",
      options: {
          filter: false,
          sort: false,
          empty: true,

          customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
              return (
                  <div style={{ marginLeft: '0px' }}>
                      
                      <Tooltip title={"Edit"}>
                          <IconButton onClick={() =>
                              
                              handleEdit(dataIndex)
                          }>
                              <EditIcon></EditIcon>
                          </IconButton>
                      </Tooltip>

                      <Tooltip title={"Delete"}>
                          <IconButton onClick={() =>
                              
                              handleDelete(dataIndex)
                          }>
                              <DeleteIcon></DeleteIcon>
                          </IconButton>
                      </Tooltip>


                  </div>

              );
          }
      }
  },
   ];
   

  useEffect(() => {

    fetchData()

  },[]);

  const fetchData = () => {
    Nw.Get(commonConst.GET_ALL_SERVICE,commonConst.getCommonHeader()).then(response=>{

      if (response.data.code === 200) {

        const srcData = response.data.success;
        setData(srcData)
        const db = new DBService()
        
        for (let index = 0; index < srcData.length; index++) {
          const element = srcData[index];
          db.put("services",element)
        }
        
      }
    }).catch(error => {

      console.log(error);
  })
  }

  const[openInAddMode,isOpenInAddMode] = useState(false)
  const[openInEditMode,isOpenInEditMode] = useState(false)
  const[data, setData] = useState([])
  const[editData, setEditData] = useState(undefined)

  const handleClickOpen = () => {
    isOpenInAddMode(true);
  };

  const handleClose = (event: React.MouseEvent, reason: String) => {
    if (reason && reason === "backdropClick") 
      return;
      close()
  };

  const close = () => {
    isOpenInAddMode(false);
    isOpenInEditMode(false)
    setEditData(undefined)

  };

  return (
    <div style={{display:"grid"}}>

<div style={{display:"flex", justifyContent:"space-between",marginBottom:"10px",alignItems: "center"}}>
    <div>
    <Typography variant='h6'>Service List</Typography>
    <Typography variant='body1'>{data.length} Available Service(s)</Typography>
    </div>
   
<Button variant="contained" startIcon={<AddIcon/>} sx={addBtnSx} onClick={handleClickOpen}>Add new Service</Button>

<Dialog open={openInAddMode || openInEditMode} onClose={handleClose}>
        <DialogTitle>{openInAddMode ? "Add New Service": openInEditMode? "Update Service Details" : ""}</DialogTitle>
        <DialogContent dividers style={{padding: "0px"}}>
          
          <ServicesForm handleClose={close} editData={editData} refreshData={fetchData} openMode={openInAddMode ? commonConst.MODE_ADD : commonConst.MODE_EDIT}/>
        </DialogContent>
      
</Dialog>

</div>
        <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
        />
    </div>
  );
};

export default ServicesPage;
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Moment from 'moment';
import { Typography } from '@mui/material';
import { FilterType } from 'mui-datatables'
import { PatientForm } from './PatientForm';
import * as commonConst from '../../components/common/Common';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AppointmentIcon from '../../assets/icons/AppointmentIcon';
import { ConsultationForm } from '../consultation/ConsultationForm';
import HistoryIcon from '@mui/icons-material/History';
import PatientHistory from './PatientHistory';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import SpaIcon from '@mui/icons-material/Spa';
import TherapyDetails from '../therapy/TherapyDetails';
import { EquipmentPurchaseForm } from '../equipment/EquipmentPurchaseForm';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { DBService } from '../../components/common/DBServices';
import * as Nw from "../../components/common/NetworkInterface";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const addBtnSx = {
  backgroundColor: colorConfigs.themeColor, borderRadius: '25px', float: "right", height: 'fit-content',
  "&:hover": {
    backgroundColor: colorConfigs.themeColor
  }
};


const PatientPage = () => {

  const options = {
    filterType: 'multiselect' as FilterType,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPageOptions:[20,50,100],
    rowsPerPage:20,
    onDownload: (buildHead: (columns: any) => string, buildBody: (data: any) => string, columns: any, rows: any) => {
   
    (rows as Array<any>).forEach(element => {
  
       (element.data as Array<any>)[4] = Moment((element.data as Array<any>)[4]).local().format("DD-MMM-YYYY");
       (element.data as Array<any>)[5] = sites.get((element.data as Array<any>)[5]);
    });
    return "\uFEFF" + buildHead(columns) + buildBody(rows);
  },
  };

  const handleEdit = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    isOpenInEditMode(true)
  }

  const handleAddConsultation = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    addConsultationMode(true)
  }

  const handleAddPurchase = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    addPurchaseMode(true)
  }

  const handleShowHistory = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    setOpenHistory(true)
  }

  const handleTherapyDlgOpen = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    setOpenTherapyDlg(true)
  }

  const columns = [

    {
      name: "patient_id",
      label: "Patient ID",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
      }
    },

    {
      name: "sex",
      label: "Gender",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "contact_no",
      label: "Mobile No.",
      options: {
        filter: false,
        sort: false,

      }
    },
    {
      name: "date",
      label: "Registration Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any) => {
          return (
            Moment(value).local().format("DD-MMM-YYYY")

          );
        }
      }
    },
    {
      name: "site_id",
      label: "Site Visted",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) => {

          return (
            sites.get(value)
          );
        }
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,

        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
          return (
            <div style={{ marginLeft: '0px' }}>

              <Tooltip title={"Edit"}>
                <IconButton onClick={() =>

                  handleEdit(dataIndex)
                }>
                  <EditIcon></EditIcon>
                </IconButton>
              </Tooltip>

              <Tooltip title={"Add Consultation"}>
                <IconButton onClick={() =>

                  handleAddConsultation(dataIndex)
                }>
                  <AppointmentIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Add Equipment Purchase"}>
                <IconButton onClick={() =>

                  handleAddPurchase(dataIndex)
                }>
                  <ShoppingCartIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Therapy"}>
                <IconButton onClick={() =>

                  handleTherapyDlgOpen(dataIndex)
                }>
                  <SpaIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Show History"}>
                <IconButton onClick={() =>

                  handleShowHistory(dataIndex)
                }>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>

            </div>

          );
        }
      }
    },
  ];

  const [sites, setSites] = React.useState(new Map())
  useEffect(() => {

    const db = new DBService()
    const siteArr = new Map<string,string>()
     db.getAll("sites").then(sites=>{

        if(sites){
            
            for (let index = 0; index < sites.length; index++) {
                const element = sites[index];
                siteArr.set(element._id,element.name)
        }
        }

        setSites(siteArr)
     })
    

},[]);

  useEffect(() => {

    fetchData()

  }, []);

  const fetchData = () => {

    Nw.Get(commonConst.GET_ALL_PATIENT).then(response => {

      if (response.data.code === 200) {

        const srcData = response.data.success;
        setData(srcData)
      }
    }).catch(error => {

      console.log(error);
    })
  }

  const [openInAddMode, isOpenInAddMode] = useState(false)
  const [openInEditMode, isOpenInEditMode] = useState(false)
  const [addConsultation, addConsultationMode] = useState(false)
  const [addPurchase, addPurchaseMode] = useState(false)
  const [openHistory, setOpenHistory] = useState(false)
  const [openTherapyDlg, setOpenTherapyDlg] = useState(false)
  const [data, setData] = useState([])
  const [editData, setEditData] = useState(undefined)

  const handleClickOpen = () => {
    isOpenInAddMode(true);
  };

  const handleClose = (event: React.MouseEvent, reason: String) => {
    if (reason && reason === "backdropClick")
      return;
    close()
  };

  const close = () => {
    isOpenInAddMode(false);
    isOpenInEditMode(false)
    setEditData(undefined)
    addConsultationMode(false)
    addPurchaseMode(false)
    setOpenHistory(false)
    setOpenTherapyDlg(false)
  };

  return (
    <div style={{ display: "grid" }}>

      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", alignItems: "center" }}>
        <div>
          <Typography variant='h6'>Patient List</Typography>
          <Typography variant='body1'>{data.length} Registered Patient(s)</Typography>
        </div>

        <Button variant="contained" startIcon={<AddIcon />} sx={addBtnSx} onClick={handleClickOpen}>Register new patient</Button>

        <Dialog open={openInAddMode || openInEditMode} onClose={handleClose}>
          <DialogTitle>{openInAddMode ? "Register New Patient" : openInEditMode ? "Update Patient details" : ""}</DialogTitle>
          <DialogContent dividers style={{ padding: "0px" }}>

            <PatientForm handleClose={close} editData={editData} refreshData={fetchData} openMode={openInAddMode ? commonConst.MODE_ADD : commonConst.MODE_EDIT} />
          </DialogContent>

        </Dialog>

        <Dialog open={addConsultation} onClose={handleClose}>
          <DialogTitle>Add New Consultation</DialogTitle>
          <DialogContent dividers style={{ padding: "0px" }}>

            <ConsultationForm handleClose={close} editData={editData} refreshData={fetchData} openMode={commonConst.MODE_ADD} />
          </DialogContent>

        </Dialog>

        <Dialog open={addPurchase} onClose={handleClose}>
          <DialogTitle>Add New Purchase</DialogTitle>
          <DialogContent dividers style={{ padding: "0px" }}>

            <EquipmentPurchaseForm handleClose={close} editData={editData} refreshData={fetchData} openMode={commonConst.MODE_ADD} />
          </DialogContent>

        </Dialog>

        <Dialog
          fullScreen
          open={openHistory}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <PatientHistory editData={editData} openMode={commonConst.MODE_VIEW} refreshData={fetchData} handleClose={close}></PatientHistory>
        </Dialog>
        <Dialog
          fullScreen
          open={openTherapyDlg}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <TherapyDetails editData={editData} handleClose={close} openMode={commonConst.MODE_ADD} refreshData={fetchData}></TherapyDetails>
        </Dialog>
      </div>
      <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default PatientPage;
import { Outlet } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Box, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import { DBService } from '../../components/common/DBServices';
import { useNavigate } from "react-router-dom"

const MainLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {

    fetchData()

  }, []);

  const fetchData = () => {


    Nw.Get(commonConst.GET_ALL_SITE, commonConst.getCommonHeader()).then(response => {
      const db = new DBService()
      if (response.data.code === 200) {

        const srcData = response.data.success;
        for (let index = 0; index < srcData.length; index++) {
          const element = srcData[index];
          db.put("sites", element)
        }

      }

      Nw.Get(commonConst.GET_ALL_SERVICE, commonConst.getCommonHeader()).then(response => {

        if (response.data.code === 200) {

          const srcData = response.data.success;

          for (let index = 0; index < srcData.length; index++) {
            const element = srcData[index];
            db.put("services", element)
          }

        }

        navigate("patients")
      }).catch(error => {

        console.log(error);
      })


    }).catch(error => {

      console.log(error);
    })


  }
  return (
    <Box sx={{ display: "flex" }}>
      <Topbar />
      <Box
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
          marginBottom: '20px'
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      <Box sx={{ position: 'fixed', bottom: '0px', left: `calc(${sizeConfigs.sidebar.width})`, width: `calc(100% - ${sizeConfigs.sidebar.width})`, 'height': '20px', backgroundColor: colorConfigs.mainBg }}>
        <Typography sx={{ textAlign: 'center', fontSize: '0.75rem' }}>Site Developed and Maintained by Mobspace. Copyright &#169; 2023 Pratidhwani</Typography>
      </Box>
    </Box>
  );
};

export default MainLayout;
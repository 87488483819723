import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { CssTextField } from '../../components/common/customTextFiled';
import { Button } from '@mui/material';
import { btnSx } from '../../configs/colorConfigs';
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import { FuncProps } from '../../components/common/Common';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';


export const SiteForm = (fnProps: FuncProps) =>{

 const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

    const [name, setName] = useState(fnProps.editData ? fnProps.editData.name: "");
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const [locality, setLocality] = useState(fnProps.editData ? fnProps.editData.locality: "");
    const handleLocalityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocality(event.target.value);
    };

    const [pinCode, setpinCode] = useState(fnProps.editData ? fnProps.editData.pincode: "");
    const handlepinCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setpinCode(Math.max(0, parseInt(event.target.value) ).toString().slice(0,6))
    };

    const [address, setAddress] = useState(fnProps.editData ? fnProps.editData.address: "");
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const [pocName, setPocName] = useState(fnProps.editData ? fnProps.editData.poc_name: "");
    const handlePocNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPocName(event.target.value);
    };

    const [contactNo, setContactNo] = useState(fnProps.editData ? fnProps.editData.contact_no: "");
    const handlecontactNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactNo(Math.max(0, parseInt(event.target.value) ).toString().slice(0,10))
    };

    const [isActive, setActive] = useState(fnProps.editData ? fnProps.editData.isActive: true);
    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.value === "1");
    };

    const [successOpen, setSuccess] = useState(false);
    const [errorOpen, setError] = useState(false);

    const handleSubmit = (event:React.FormEvent<HTMLFormElement> ) => {
        event.preventDefault()

        const dataObj = {
            name : name,
            locality : locality,
            address : address,
            pincode : pinCode,
            poc_num : contactNo,
            isActive : isActive,
            poc_name : pocName,
            _id: ''
        }

        if(fnProps.editData){
            dataObj._id = fnProps.editData._id
        }

        Nw.Post(fnProps.editData ? commonConst.EDIT_SITE: commonConst.ADD_SITE,dataObj,commonConst.getCommonHeader()).then(response => {

            if(response.data.code === 200){
                setSuccess(true)
            }else{
                setError(true)
            }

        }).catch(error => {

            console.log(error);
            setError(true)
        })
    };

    const handleClose = () =>{
        fnProps.handleClose()
    }

    const handleAPISuccess = () =>{
        fnProps.refreshData()
        fnProps.handleClose()
    }

    const handleCloseSnakbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccess(false)
        setError(false)
      };

    return(
        <div>
             <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={0.5} style={{padding:"24px"}}>
                <Grid item xs={6}>
                <CssTextField required name='name' fullWidth value={name} label="Name" onChange={handleNameChange}/>
                </Grid>
                <Grid item xs={6}>
                <CssTextField required name='locality' fullWidth value={locality} label="Locality" onChange={handleLocalityChange}/>
                </Grid>
                <Grid item xs={12}>
                <CssTextField required name='address' fullWidth multiline maxRows={4} value={address} label="Address" onChange={handleAddressChange}/>
                </Grid>
                <Grid item xs={4}>
                <CssTextField required name='pincode' type="number" fullWidth value={pinCode} label="Pin Code" onChange={handlepinCodeChange}/>
                </Grid>
                <Grid item xs={4}>
                <CssTextField select label="Status" name='isActive' fullWidth defaultValue="1" value={isActive?"1":"0"} onChange={handleActiveChange}>
                <MenuItem value="1">
                    Active
                </MenuItem>
                <MenuItem value="0">
                    InActive
                </MenuItem>
                </CssTextField>
                </Grid>
                <Grid item xs={6}>
                <CssTextField  name='poc_name' fullWidth value={pocName} label="POC Name" onChange={handlePocNameChange}/>
                </Grid>
                <Grid item xs={6}>
                <CssTextField name='poc_num' type="number" fullWidth value={contactNo} label="POC Contact number" onChange={handlecontactNoChange}/>
                </Grid>
                
            </Grid>
            <Divider style={{marginTop:'0px'}}/>
            <div style={{padding:'10px',float:'right'}}>
            <Button sx={btnSx} onClick={handleClose} >Cancel</Button>
            <Button sx={btnSx} type='submit'>{fnProps.editData ? "Update": "Save"}</Button>
            </div>
           
            </form>

            <Snackbar open={successOpen} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} autoHideDuration={3000} onClose={handleAPISuccess}>
                <Alert onClose={handleCloseSnakbar} severity="success" sx={{ width: '100%' }}>
                Data saved successfully
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
                Error while saving data
                </Alert>
            </Snackbar>

        </div>
    )
};
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Divider, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CssTextField } from '../../components/common/customTextFiled';
import { Button } from '@mui/material';
import { btnSx } from '../../configs/colorConfigs';
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import { FuncProps } from '../../components/common/Common';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { DBService } from '../../components/common/DBServices';

export const PatientForm = (fnProps: FuncProps) => {


    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [sites, setSites] = React.useState<any[]>([]);

    useEffect(() => {

        const db = new DBService()
        const siteArr: any[] = []
        db.getAll("sites").then(sites => {

            if (sites) {

                for (let index = 0; index < sites.length; index++) {
                    const element = sites[index];
                    if (element.isActive == true) {
                        const data = {
                            _id: element._id,
                            name: element.name
                        }
                        siteArr.push(data)
                    }
                }

                if (fnProps.openMode === commonConst.MODE_ADD)
                    setsiteId(siteArr[0]._id)

                setSites(siteArr)
            }
        })

    }, []);

    const [name, setName] = useState(fnProps.editData ? fnProps.editData.name : "");
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const [guardianName, setGuardianName] = useState(fnProps.editData ? fnProps.editData.guardian : "");
    const handleguardianNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGuardianName(event.target.value);
    };

   

    const [address, setAddress] = useState(fnProps.editData ? fnProps.editData.address : "");
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };


    const [contactNo, setContactNo] = useState(fnProps.editData ? fnProps.editData.contact_no : "");
    const handlecontactNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactNo(Math.max(0, parseInt(event.target.value)).toString().slice(0, 10))
    };

    const [emailId, setEmailId] = useState(fnProps.editData ? fnProps.editData.email_id : "");
    const handleEmailIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailId(event.target.value);
    };

    const [sex, setGender] = useState(fnProps.editData ? fnProps.editData.sex : "");
    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(event.target.value);
    };

    const [siteId, setsiteId] = useState(fnProps.editData ? fnProps.editData.site_id : "");
    const handleSiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setsiteId(event.target.value);
    };

    const [dob, setDob] = React.useState<Dayjs | null>(
        fnProps.editData ? fnProps.editData.dob : dayjs('2014-08-18T21:11:54'),
    );

    const [dor, setDor] = React.useState<Dayjs | null>(
        fnProps.editData ? fnProps.editData.date : dayjs(new Date()),
    );

    const handleChange = (newValue: Dayjs | null) => {
        setDob(newValue);
    };

    const handleDorChange = (newValue: Dayjs | null) => {
        setDor(newValue);
    };

    const [successOpen, setSuccess] = useState(false);
    const [errorOpen, setError] = useState(false);

    const [referedBy, setReferedBy] = useState(fnProps.editData ? fnProps.editData.ref_by : "");
    const handleRefBy = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReferedBy(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const patientObj = {
            name: name,
            guardian: guardianName,
            address: address,
            date: dor,
            contact_no: contactNo,
            email_id: emailId,
            sex: sex,
            dob: dob,
            ref_by: referedBy,
            site_id: siteId,
            _id: ''
        }

        if (fnProps.editData) {
            patientObj._id = fnProps.editData._id
        }

        Nw.Post(fnProps.editData ? commonConst.EDIT_PATIENT : commonConst.ADD_PATIENT, patientObj, commonConst.getCommonHeader()).then(response => {

            console.log(response)

            if (response.data.code === 200) {
                setSuccess(true)
            } else {
                setError(true)
            }

        }).catch(error => {

            console.log(error);
            setError(true)
        })
    };

    const handleClose = () => {
        fnProps.handleClose()
    }

    const handleAPISuccess = () => {
        fnProps.refreshData()
        fnProps.handleClose()
    }

    const handleCloseSnakbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccess(false)
        setError(false)
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={1.5} columnSpacing={0.5} style={{ padding: "24px" }}>
                    <Grid item xs={6}>
                        <CssTextField required name='name' fullWidth value={name} label="Name" onChange={handleNameChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField name='guardian_name' fullWidth value={guardianName} label="Guardian Name" onChange={handleguardianNameChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <CssTextField required name='address' fullWidth multiline maxRows={4} value={address} label="Address" onChange={handleAddressChange} />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <CssTextField select label="Gender" name='sex' fullWidth defaultValue="male" value={sex} onChange={handleGenderChange}>
                            <MenuItem value="male">
                                Male
                            </MenuItem>
                            <MenuItem value="female">
                                Female
                            </MenuItem>
                        </CssTextField>
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField select label="Visiting Site" name='siteId' fullWidth defaultValue="male" value={siteId} onChange={handleSiteChange}>
                            {
                                sites && sites.map((value: any) => {
                                    return (
                                        <MenuItem value={value._id} key={value._id}>{value.name}</MenuItem>
                                    )
                                })
                            }
                        </CssTextField>
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField required name='contact_no' type="number" fullWidth value={contactNo} label="Contact No" onChange={handlecontactNoChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField name='email_id' fullWidth value={emailId} label="Email Id" onChange={handleEmailIdChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                            <DesktopDatePicker
                                label="DOB"
                                inputFormat="MM/DD/YYYY"
                                value={dob}

                                onChange={handleChange}
                                renderInput={(params) => <CssTextField {...params} required fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                            <DesktopDatePicker
                                label="Registration Date"
                                inputFormat="MM/DD/YYYY"
                                value={dor}

                                onChange={handleDorChange}
                                renderInput={(params) => <CssTextField {...params} required fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField name='refered_by' fullWidth value={referedBy} label="Reffered By" onChange={handleRefBy} />
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: '0px' }} />
                <div style={{ padding: '10px', float: 'right' }}>
                    <Button sx={btnSx} onClick={handleClose} >Cancel</Button>
                    <Button sx={btnSx} type='submit'>{fnProps.editData ? "Update" : "Save"}</Button>
                </div>

            </form>

            <Snackbar open={successOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleAPISuccess}>
                <Alert onClose={handleCloseSnakbar} severity="success" sx={{ width: '100%' }}>
                    Data saved successfully
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
                    Error while saving data
                </Alert>
            </Snackbar>

        </div>
    )
};
import { DBService } from "./DBServices"

//export const BASE_URL = "http://127.0.0.1:8080/"
export const BASE_URL = "/";

export const ADD_PATIENT = BASE_URL + "patient/registerPatient"
export const GET_ALL_PATIENT = BASE_URL + "patient/getList"
export const EDIT_PATIENT = BASE_URL + "patient/updateData"
export const SEARCH_PATIENT = BASE_URL + "patient/getData"

export const ADD_USER = BASE_URL + "user/createUser"
export const GET_ALL_USER = BASE_URL + "user/getList"
export const EDIT_USER = BASE_URL + "user/updateData"
export const RESET_PASS = BASE_URL + "user/generateTempToken"

export const ADD_SITE = BASE_URL + "sites/saveData"
export const GET_ALL_SITE = BASE_URL + "sites/getList"
export const EDIT_SITE = BASE_URL + "sites/updateData"

export const GET_ALL_CONULTATION = BASE_URL + "consultation/getList"
export const ADD_CONSULTATION = BASE_URL + "consultation/saveData"
export const EDIT_CONSULTATION = BASE_URL + "consultation/updateData"
export const GET_PATIENT_CONSULTATION = BASE_URL + "consultation/getPatientData"

export const ADD_SERVICE = BASE_URL + "services/saveData"
export const GET_ALL_SERVICE = BASE_URL + "services/getList"
export const EDIT_SERVICE = BASE_URL + "services/updateData"
export const DELETE_SERVICE = BASE_URL + "services/deleteData"

export const ADD_THERAPY = BASE_URL + "therapy/saveData"
export const GET_PATIENT_THERAPY = BASE_URL + "therapy/getPatientData"
export const UPDATE_PATIENT_THERAPY = BASE_URL + "therapy/updateTherapySession"

export const GET_ALL_EQUIPMENT_PURCHASE = BASE_URL + "equipmentPurchase/getList"
export const ADD_EQUIPMENT_PURCHASE = BASE_URL + "equipmentPurchase/saveData"
export const EDIT_EQUIPMENT_PURCHASE = BASE_URL + "equipmentPurchase/updateData"
export const GET_PATIENT_EQUIPMENT_PURCHASE = BASE_URL + "equipmentPurchase/getPatientData"
export const GET_PATIENT_PURCHASE = BASE_URL + "equipmentPurchase/getPatientData"

export const REFRESH_TOKEN = BASE_URL + "auth/refreshToken"
export const LOGIN = BASE_URL + "auth/login"
export const SET_PASS = BASE_URL + "auth/setPassword"


export function getCommonHeader() {
  let config = {
    headers: {

      "token": "webapp"
    }
  }

  return undefined;
}

export interface FuncProps {
  handleClose: () => void;
  refreshData: () => void;
  editData: any;
  openMode: number;
}

export async function getServiceNameFromId(id: string) {
  const db = new DBService()
  const val = await db.get("services", id)
  if (val) {
    return val.name
  }
  return ""

}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const MODE_ADD = 0
export const MODE_EDIT = 1
export const MODE_VIEW = 2



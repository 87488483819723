import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { FilterType } from 'mui-datatables'
import Moment from 'moment';
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ConsultationForm } from './ConsultationForm';
import { DBService } from '../../components/common/DBServices';
import EditIcon from '@mui/icons-material/Edit';

const options = {
  filterType: 'multiselect' as FilterType,
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true
};

const addBtnSx = {
  backgroundColor: colorConfigs.themeColor, borderRadius: '25px', float: "right", height: 'fit-content',
  "&:hover": {
    backgroundColor: colorConfigs.themeColor
  }
};


const ConsultationPage = () => {

  const [duration, setDuration] = useState("1")
  const handleEdit = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    isOpenInEditMode(true)
    setDialogMode(commonConst.MODE_EDIT)
  }
  const handleView = (index: number) => {
    const editData = data[index]
    setEditData(editData)
    isOpenInEditMode(true)
    setDialogMode(commonConst.MODE_VIEW)
  }
  const handleChange = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string);
    const dateData = getStartEndDateAgainstDuration(parseInt(event.target.value))
    fetchDateBasedOnFilter(dateData)
  };

  const columns = [

    {
      name: "name",
      label: "Patient Name",
      options: {
        filter: false,
        sort: false,
      }
    },

    {
      name: "patient_id",
      label: "Patient Id",
      options: {
        filter: false,
        sort: false,
      }
    },

    {
      name: "doctor_name",
      label: "Doctor Name",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "date_time",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            Moment(value).local().format("DD-MMM-YYYY")

          );
        }
      }
    },
    {
      name: "visiting_site_id",
      label: "Site Visted",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) => {

          return (
            sites.get(value)
          );
        }
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,

        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
          return (
            <div style={{ marginLeft: '0px' }}>

              <Tooltip title={"View"}>
                <IconButton onClick={() =>

                  handleView(dataIndex)
                }>
                  <VisibilityIcon></VisibilityIcon>
                </IconButton>
              </Tooltip>

              <Tooltip title={"Edit"}>
                <IconButton onClick={() =>

                  handleEdit(dataIndex)
                }>
                  <EditIcon></EditIcon>
                </IconButton>
              </Tooltip>
            </div>

          );
        }
      }
    },
  ];


  useEffect(() => {

    fetchData()

  }, []);
  const [sites, setSites] = React.useState(new Map())
  useEffect(() => {

    const db = new DBService()
    const siteArr = new Map<string, string>()
    db.getAll("sites").then(sites => {

      if (sites) {

        for (let index = 0; index < sites.length; index++) {
          const element = sites[index];
          siteArr.set(element._id, element.name)
        }
      }

      setSites(siteArr)
    })


  }, []);


  const fetchData = () => {

    const dateData = getStartEndDateAgainstDuration(parseInt(duration))
    fetchDateBasedOnFilter(dateData)

  }

  const fetchDateBasedOnFilter = (dateData: any) => {

    Nw.Get(commonConst.GET_ALL_CONULTATION, dateData).then(response => {

      if (response.data.code === 200) {

        const srcData = response.data.success;
        setData(srcData)
      }
    }).catch(error => {

      console.log(error);
    })
  }

  const [openInAddMode, isOpenInAddMode] = useState(false)
  const [dialogMode, setDialogMode] = useState(commonConst.MODE_ADD)
  const [openInEditMode, isOpenInEditMode] = useState(false)
  const [data, setData] = useState([])
  const [editData, setEditData] = useState(undefined)

  const handleClickOpen = () => {
    isOpenInAddMode(true);
  };

  const handleClose = (event: React.MouseEvent, reason: String) => {
    if (reason && reason === "backdropClick")
      return;
    close()
  };

  const close = () => {
    isOpenInAddMode(false);
    isOpenInEditMode(false)
    setEditData(undefined)
    setDialogMode(commonConst.MODE_ADD)

  };

  const getYearOptions = () => {
    const d = new Date();
    const year = d.getFullYear();

    const years = []

    for (let index = year; index > 2023; index--) {
      years.push(index)
    }
    years.push(2023)
    return years
  }

  const getStartEndDateAgainstDuration = (duration: number) => {

    var date = new Date();

    const dateData = { start_date: 0, end_date: 0 }

    switch (duration) {

      case 1:
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        dateData.start_date = (Date.UTC(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate()))
        dateData.end_date = (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()))

        break;

      case 3:
      case 6:

        date.setMonth(date.getMonth() - duration);
        date.setDate(1)
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)

        dateData.start_date = (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()))

        const lstDate = new Date();
        lstDate.setDate(0)
        lstDate.setHours(23)
        lstDate.setMinutes(59)
        lstDate.setSeconds(59)
        dateData.end_date = (Date.UTC(lstDate.getFullYear(), lstDate.getMonth(), lstDate.getDate(), lstDate.getHours(), lstDate.getMinutes(), lstDate.getSeconds(), lstDate.getMilliseconds()))

        break;
      default:
        const firstDayOfYear = new Date(duration, 0, 1);
        dateData.start_date = (Date.UTC(firstDayOfYear.getFullYear(), firstDayOfYear.getMonth(), firstDayOfYear.getDate(), firstDayOfYear.getHours(), firstDayOfYear.getMinutes(), firstDayOfYear.getSeconds(), firstDayOfYear.getMilliseconds()))

        firstDayOfYear.setFullYear(duration + 1)
        dateData.end_date = (Date.UTC(firstDayOfYear.getFullYear(), firstDayOfYear.getMonth(), firstDayOfYear.getDate(), firstDayOfYear.getHours(), firstDayOfYear.getMinutes(), firstDayOfYear.getSeconds(), firstDayOfYear.getMilliseconds()))

        break;
    }

    return dateData

  }

  return (
    <div style={{ display: "grid" }}>

      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px", alignItems: "center" }}>
          <Typography variant='body1'>Showing consultations for&nbsp;&nbsp;</Typography>
          <Select
            value={duration}
            variant='standard'
            onChange={handleChange}
          >
            <MenuItem value={'1'}>Current Month</MenuItem>
            <MenuItem value={'3'}>Last 3 Months</MenuItem>
            <MenuItem value={'6'}>Last 6 Months</MenuItem>
            {
              getYearOptions().map((value: number) => {
                return (
                  <MenuItem value={value} key={value}>{value}</MenuItem>
                )
              })
            }

          </Select>
        </div>

        <Button variant="contained" startIcon={<AddIcon />} sx={addBtnSx} onClick={handleClickOpen}>Add new Consultation</Button>

        <Dialog open={openInAddMode || openInEditMode} onClose={handleClose}>
          <DialogTitle>{openInAddMode ? "Add New Consultation" : openInEditMode ? "View Consultation" : ""}</DialogTitle>
          <DialogContent dividers style={{ padding: "0px" }}>

            <ConsultationForm handleClose={close} editData={editData} refreshData={fetchData} openMode={dialogMode} />
          </DialogContent>

        </Dialog>

      </div>
      <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default ConsultationPage;
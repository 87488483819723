import React from "react";
import { SvgIcon } from '@mui/material';

const AppointmentIcon = props => (

    <SvgIcon viewBox="0 0 512.000000 512.000000" {...props}>

<g>
<path d="M142.253,384.196h30.604V414.8c0,11.28,9.145,20.426,20.426,20.426s20.426-9.145,20.426-20.426v-30.604h30.603 c11.28,0,20.426-9.145,20.426-20.426s-9.145-20.426-20.426-20.426h-30.603v-30.604c0-11.28-9.145-20.426-20.426-20.426 s-20.426,9.145-20.426,20.426v30.604h-30.604c-11.28,0-20.426,9.145-20.426,20.426S130.973,384.196,142.253,384.196z"></path> <path d="M443.893,0H68.107c-11.28,0-20.426,9.145-20.426,20.426v471.149c0,11.28,9.145,20.426,20.426,20.426h270.957 c5.031,0,10.452-1.989,14.442-5.983l104.829-104.829c3.775-3.773,5.983-9.031,5.983-14.444V20.426 C464.319,9.145,455.175,0,443.893,0z M359.489,442.263v-35.092h35.092C371.538,430.215,377.374,424.377,359.489,442.263z M423.469,366.32h-0.001h-84.404c-11.28,0-20.426,9.145-20.426,20.426v84.404H88.532V40.851h334.937V366.32z"></path> <path d="M142.253,249.153H247.83c11.28,0,20.426-9.145,20.426-20.426s-9.145-20.426-20.426-20.426H142.253 c-11.28,0-20.426,9.145-20.426,20.426S130.973,249.153,142.253,249.153z"></path> <path d="M142.253,186.469h227.494c11.28,0,20.426-9.145,20.426-20.425s-9.145-20.426-20.426-20.426H142.253 c-11.28,0-20.426,9.145-20.426,20.426S130.973,186.469,142.253,186.469z"></path> <path d="M142.253,123.783h227.494c11.28,0,20.426-9.145,20.426-20.426s-9.145-20.426-20.426-20.426H142.253 c-11.28,0-20.426,9.145-20.426,20.426S130.973,123.783,142.253,123.783z"></path> 

</g>

    </SvgIcon>

);

export default AppointmentIcon;

import { openDB } from 'idb';

const DATABASE_NAME = 'partidhwani';
const DATABASE_VERSION = 1;
//const db = idb.default;
let dbPromise;

(async () => {

    dbPromise = openDB(DATABASE_NAME, DATABASE_VERSION, {
        upgrade(db, oldVersion, newVersion, transaction) {
            
            switch (oldVersion) {
               
                case 0:
                    if (!db.objectStoreNames.contains("sites")) {
                        db.createObjectStore("sites", {
                            keyPath: '_id',
                            autoIncrement: false,
                        });
                    }
                    if (!db.objectStoreNames.contains("services")) {
                        db.createObjectStore("services", {
                            keyPath: '_id',
                            autoIncrement: false,
                        });
                    }
                    
                    break;
                case 1:
                    console.log('Creating object store');
                   
                default:

                    break;

            }

        },
        blocked() {
            console.log("blocked DB")
        },
        blocking() {
            console.log("blocking DB")
        },
        terminated() {
            console.log("terminated DB")
        },
    });

})();

//openDb();

export class DBService {
    constructor() {

        if (DBService.instance instanceof DBService) {
            console.debug("Returning from singleton")
            return DBService.instance;
        }

        Object.freeze(this);
        Object.freeze(dbPromise);
        DBService.instance = this;

    }
     get(tablespace, key) {
        return dbPromise.then(db => {
            return db.transaction(tablespace).objectStore(tablespace).get(key);
        }).catch(error => {
            // Do something?
        });
    }

    async getAll(tablespace) {
        return dbPromise.then(db => {
            return db.transaction(tablespace).objectStore(tablespace).getAll();
        }).catch(error => {
            // Do something?
        });
    }

    async put(tablespace, object, key = null) {

        return dbPromise.then(db => {

            if (key) {
                return db.transaction(tablespace, 'readwrite').objectStore(tablespace).put(object, key);
            }
            return db.transaction(tablespace, 'readwrite').objectStore(tablespace).put(object);
        }).catch(error => {
            alert(error)
        });
    }

    async putAll(tablespace, objects, clearPrevData = true){

        if(clearPrevData){
            this.deleteAll(tablespace)
        }

        objects.map((object,index)=>(
            this.put(tablespace,object)
        )

        )
    }

    async delete(tablespace, key) {
        return dbPromise.then(db => {
            return db.transaction(tablespace, 'readwrite').objectStore(tablespace).delete(key);
        }).catch(error => {
            // Do something?
        });
    }



    async deleteAll(tablespace) {
        return dbPromise.then(db => {
            return db.transaction(tablespace, 'readwrite').objectStore(tablespace).clear();
        }).catch(error => {
            // Do something?
        });
    }
}
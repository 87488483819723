import React, { useState, useEffect } from 'react';
import assets from '../../assets';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import * as commonConst from '../../components/common/Common';
import { FuncProps } from '../../components/common/Common';
import * as Nw from "../../components/common/NetworkInterface";
import Moment from 'moment'
import colorConfigs from "../../configs/colorConfigs";
import { DBService } from '../../components/common/DBServices';

const trtd = {
    border: '1px solid black',
    textAlign: 'center',
    padding: '15px 5px'
}

const trtd_left = {
    border: '1px solid black',
    textAlign: 'left',
    padding: '15px 5px'
}

const no_print = {

}

const trtd_right = {
    border: '1px solid black',
    textAlign: 'right',
    padding: '5px'
}

const tableCellStyle25 = {
    width: '25%',
    border: '1px solid black',
    padding: '15px 5px'
}

const tableCellStyle33 = {
    width: '33.33%',
    border: '1px solid black',
    padding: '15px 5px'
}

const PatientHistory = (fnProps: FuncProps) => {
    const [name] = useState(fnProps.editData ? fnProps.editData.name : "");
    const [guardianName] = useState(fnProps.editData ? fnProps.editData.guardian : "");
    const [address] = useState(fnProps.editData ? fnProps.editData.address : "");
    const [contactNo] = useState(fnProps.editData ? fnProps.editData.contact_no : "");
    const [emailId] = useState(fnProps.editData ? fnProps.editData.email_id : "");
    const [sex] = useState(fnProps.editData ? fnProps.editData.sex : "");
    const [patinetId] = useState(fnProps.editData ? fnProps.editData.patient_id : "");
    const [consultationData, setConsultationData] = React.useState<any[]>([]);
    const [therapyData, setTherapyData] = React.useState<any[]>([]);
    const [purchaseData, setPurchaseData] = React.useState<any[]>([]);

    useEffect(() => {

        fetchData()

    }, []);

    const fetchData = () => {
        const patientData = { patient_id: fnProps.editData.patient_id }
       

        Nw.Get(commonConst.GET_PATIENT_CONSULTATION, patientData).then(response => {

            if (response.data.code === 200) {
                const srcData = response.data.success;
                setConsultationData(srcData)
            }
        }).catch(error => {

            console.log(error);
        })

        Nw.Get(commonConst.GET_PATIENT_THERAPY, patientData).then(response => {

            if (response.data.code === 200) {
                const srcData = response.data.success;
                setTherapyData(srcData)
            }
        }).catch(error => {

            console.log(error);
        })

        Nw.Get(commonConst.GET_PATIENT_PURCHASE, patientData).then(response => {

            if (response.data.code === 200) {
                const srcData = response.data.success;
                setPurchaseData(srcData)
            }
        }).catch(error => {

            console.log(error);
        })

        readSites()
        readTherapy()
    }
    const [sites, setSites] = React.useState(new Map())
    function readSites() {
        const db = new DBService
        const siteArr = new Map<string, string>()
        db.getAll("sites").then(sites => {

            if (sites) {

                for (let index = 0; index < sites.length; index++) {
                    const element = sites[index];
                    siteArr.set(element._id, element.name)
                }
            }

            setSites(siteArr)
        })

    }
    const [therapies, setTherapies] = React.useState(new Map())
    function readTherapy(){
        const db = new DBService
        db.getAll("services").then(services => {

            if (services) {
                const therapyArr = new Map<string, string>()
                for (let index = 0; index < services.length; index++) {
                    const element = services[index];
                    if(element.service_type === "Therapy"){
                        const data = {
                            _id: element._id,
                            name: element.name
                        }
                        therapyArr.set(element._id, element.name)
                    }
                }
               setTherapies(therapyArr)
            }
        })
    }

    function fnCalculateAge() {

        if (!fnProps.editData) {
            return ""
        }

        var userDateinput = fnProps.editData.dob
        // convert user input value into date object
        var birthDate = new Date(userDateinput);
        // get difference from current date;
        var difference = Date.now() - birthDate.getTime();
        var ageDate = new Date(difference);
        var calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        return (calculatedAge + " Years")
    }

    function close() {
        fnProps.handleClose()
    }

    const Print = () => {
        window.print();
    }

    function getServiceTakenString(value: any) {

        let serviceString = "";
        if (value.services) {
            for (let index = 0; index < value.services.length; index++) {
                const element = value.services[index];
                serviceString += element.name + ", "
            }
            return serviceString.substring(0, serviceString.length - 2)
        }

        return "NA"
    }

    function getTherapyPackageName(value: string) {

        switch (value) {

            case 'one_time':
                return "Single Session"

            case '1m3dpw':
                return "Monthly Package, 3D/Week"

            case '1m2dpw':
                return "Monthly Package, 2D/Week"

        }
    }

    function getTherapyTakenDates(value: Array<number>) {

        let serviceString = "";
        if (value) {
            for (let index = 0; index < value.length; index++) {
                const element = value[index];
                serviceString += Moment(element).local().format("DD-MMM-YYYY") + ", "
            }
            return serviceString.substring(0, serviceString.length - 2)
        }

        return "NA"
    }

    return (
        <div>
            <div className="appbar">
                <style>
                    {`@media print {.appbar{display: none;}}`}
                </style>
                <AppBar sx={{ position: 'relative', background: colorConfigs.topbar.bg, }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Patient History
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={Print}
                            aria-label="close"
                        >
                            <PrintIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>
            <div id="print_div">
                <div style={{ margin: 'auto', display: 'table', padding: '10px', }} >
                    <img src={assets.images.logo} style={{ width: "250px" }} />
                </div >
                <div style={{ margin: 'auto', display: 'table', padding: '10px', width: '90%' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableBody sx={trtd}>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd} colSpan={3}><b>Patient Details</b></TableCell>
                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={1} >Name: {name}</TableCell>
                                    <TableCell sx={trtd_left} colSpan={2}>C/O: {guardianName}</TableCell>
                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={1}>Patient Id: {patinetId}</TableCell>
                                    <TableCell sx={trtd_left} colSpan={1} align="right">Age: {fnCalculateAge()}</TableCell>
                                    <TableCell sx={trtd_left} colSpan={1} align="right">Sex: {sex}</TableCell>
                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={3}>Address: {address}</TableCell>

                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={1} align="right">Contact: {contactNo}</TableCell>
                                    <TableCell sx={trtd_left} colSpan={2} align="right">Email: {emailId}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>

                        {
                            consultationData && consultationData.map((value: any) => {
                                return (

                                    <Table key={value._id} aria-label="spanning table" sx={{ marginTop: '30px' }}>
                                        <TableBody sx={trtd}>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd} colSpan={4}><b>Consultation</b></TableCell>
                                            </TableRow>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={tableCellStyle25} >Date: {Moment(value.date_time).local().format("DD-MMM-YYYY")}</TableCell>
                                                <TableCell sx={tableCellStyle25} align="left">Doctor Name: {value.doctor_name}</TableCell>
                                                <TableCell sx={tableCellStyle25} align="left">Visiting Site: {sites.get(value.visiting_site_id)}</TableCell>
                                                <TableCell sx={tableCellStyle25} align="left">Total Fee Paid : ₹{value.consulation_fee - (value.discount ? value.discount : 0)}</TableCell>
                                            </TableRow>
                                            <TableRow sx={trtd}>

                                                <TableCell sx={trtd_left} colSpan={4}><b>Services Taken: </b>{getServiceTakenString(value)}</TableCell>

                                            </TableRow>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd_left} colSpan={4}><b>Diagnosis: </b>{value.diagonsis}</TableCell>

                                            </TableRow>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd_left} colSpan={4}><b>Recomendations:  </b>{value.recommendation}</TableCell>
                                            </TableRow>

                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd_left} colSpan={4}><b>Notes:  </b>{value.notes}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                )
                            })
                        }

                        {
                            therapyData && therapyData.map((value: any) => {
                                return (

                                    <Table key={value._id} aria-label="spanning table" sx={{ marginTop: '30px' }}>
                                        <TableBody sx={trtd}>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd} colSpan={4}><b>Therapy</b></TableCell>
                                            </TableRow>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={tableCellStyle25} >Date: {Moment(value.date_time).local().format("DD-MMM-YYYY")}</TableCell>

                                                <TableCell sx={tableCellStyle25} align="left">Visiting Site: {sites.get(value.visiting_site_id)}</TableCell>
                                                <TableCell sx={tableCellStyle25} align="left">Total Fee Paid : ₹{value.fee - (value.discount ? value.discount : 0)}</TableCell>
                                            </TableRow>
                                            <TableRow sx={trtd}>

                                                <TableCell sx={trtd_left} >Therapy Name: { therapies.get(value.service_id)}</TableCell>
                                                <TableCell sx={trtd_left} >Package: {getTherapyPackageName(value.package)}</TableCell>
                                                <TableCell sx={trtd_left} >Session Remaining: {value.total_sessions - value.dates_visited.length}/{value.total_sessions}</TableCell>

                                            </TableRow>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd_left} colSpan={4}>Session Taken On: {getTherapyTakenDates(value.dates_visited)}</TableCell>

                                            </TableRow>

                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd_left} colSpan={4}>Notes: {value.notes}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                )
                            })
                        }

                        {
                            purchaseData && purchaseData.map((value: any) => {
                                return (

                                    <Table key={value._id} aria-label="spanning table" sx={{ marginTop: '30px' }}>
                                        <TableBody sx={trtd}>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd} colSpan={4}><b>Equipment Purchase</b></TableCell>
                                            </TableRow>
                                            <TableRow sx={trtd}>
                                                <TableCell sx={tableCellStyle25} >Date: {Moment(value.date_time).local().format("DD-MMM-YYYY")}</TableCell>

                                                <TableCell sx={tableCellStyle25} align="left">Visiting Site: {sites.get(value.visiting_site_id)}</TableCell>
                                                <TableCell sx={tableCellStyle25} align="left">Total Fee Paid : ₹{value.price - (value.discount ? value.discount : 0)}</TableCell>
                                            </TableRow>
                                            <TableRow sx={trtd}>

                                                <TableCell sx={trtd_left} colSpan={2} >Equipment Name: {value.equipment_name}</TableCell>
                                                <TableCell sx={trtd_left} colSpan={2}>Serial No: {value.serial_no}</TableCell>

                                            </TableRow>
                                            
                                            <TableRow sx={trtd}>
                                                <TableCell sx={trtd_left} colSpan={4}>Description: {value.description}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                )
                            })
                        }

                    </TableContainer>
                </div>
            </div>

        </div>
    )
}

export default PatientHistory
import React, { useState, useEffect } from 'react';
import assets from '../../assets';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import * as commonConst from '../../components/common/Common';
import { FuncProps } from '../../components/common/Common';
import * as Nw from "../../components/common/NetworkInterface";
import Moment from 'moment';
import colorConfigs from "../../configs/colorConfigs";
import { DBService } from '../../components/common/DBServices';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TherapyForm } from './TherapyForm';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

const trtd = {
    border: '1px solid black',
    textAlign: 'center',
    padding: '15px 5px'
}

const trtd_left = {
    border: '1px solid black',
    textAlign: 'left',
    padding: '15px 5px',
    width: '50%'
}

const no_print = {

}

const trtd_right = {
    border: '1px solid black',
    textAlign: 'right',
    padding: '5px'
}

const tableCellStyle25 = {
    width: '25%',
    border: '1px solid black',
    padding: '15px 5px'
}

const tableCellStyle33 = {
    width: '33.33%',
    border: '1px solid black',
    padding: '15px 5px'
}



const TherapyDetails = (fnProps: FuncProps) => {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [name] = useState(fnProps.editData ? fnProps.editData.name : "");
    const [guardianName] = useState(fnProps.editData ? fnProps.editData.guardian : "");
    const [address] = useState(fnProps.editData ? fnProps.editData.address : "");
    const [contactNo] = useState(fnProps.editData ? fnProps.editData.contact_no : "");
    const [emailId] = useState(fnProps.editData ? fnProps.editData.email_id : "");
    const [sex] = useState(fnProps.editData ? fnProps.editData.sex : "");
    const [patinetId] = useState(fnProps.editData ? fnProps.editData.patient_id : "");
    const [consultationData, setConsultationData] = React.useState<any[]>([]);
    const [successOpen, setSuccess] = useState(false);
    const [errorOpen, setError] = useState(false);

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


    const [open, isOpen] = useState(false)

    const handleClose = (event: React.MouseEvent, reason: String) => {
        if (reason && reason === "backdropClick")
            return;
        closeDialog()
    };

    const closeDialog = () => {
        isOpen(false)
    };

    useEffect(() => {

        fetchData()

    }, []);

    const fetchData = () => {
        const patientData = { patient_id: fnProps.editData.patient_id }
        
        Nw.Get(commonConst.GET_PATIENT_THERAPY, patientData).then(response => {

            if (response.data.code === 200) {
                const srcData = response.data.success;
                setConsultationData(srcData)
            }
        }).catch(error => {

            console.log(error);
        })

        readSites()
        readTherapy()
    }

    const updateMarkAsComplete = (id: number) => {
        const data = { _id: id }
        const headerConfig = commonConst.getCommonHeader()

        Nw.Post(commonConst.UPDATE_PATIENT_THERAPY, data, headerConfig).then(response => {

            if (response.data.code === 200) {
                setSuccess(true)
            } else {
                setError(true)
            }
        }).catch(error => {

            console.log(error);
            setError(true)
        })

    }

    const [sites, setSites] = React.useState(new Map())
    function readSites() {
        const db = new DBService
        const siteArr = new Map<string, string>()
        db.getAll("sites").then(sites => {

            if (sites) {

                for (let index = 0; index < sites.length; index++) {
                    const element = sites[index];
                    siteArr.set(element._id, element.name)
                }
            }

            setSites(siteArr)
        })

    }

    const [therapies, setTherapies] = React.useState(new Map())
    function readTherapy(){
        const db = new DBService
        db.getAll("services").then(services => {

            if (services) {
                const therapyArr = new Map<string, string>()
                for (let index = 0; index < services.length; index++) {
                    const element = services[index];
                    if(element.service_type === "Therapy"){
                        const data = {
                            _id: element._id,
                            name: element.name
                        }
                        therapyArr.set(element._id, element.name)
                    }
                }
               setTherapies(therapyArr)
            }
        })
    }

    const handleCloseSnakbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccess(false)
        setError(false)
    };

    function close() {
        fnProps.handleClose()
    }

    const Print = () => {
        isOpen(true)
    }

    const markAsDone = (event: any) => {
        updateMarkAsComplete(event.target.value)
    }

    const getFormatedCompletionDate = (date: number) => {
        return "Completed on " + moment(date).local().format("DD-MMM-YYYY")
    }

    const createTherapySessions = (therapy: any) => {
        var elements = [];

        for (var i = 0; i < therapy.total_sessions; i++) {
            elements.push(

                <TableRow key={i}>

                    <TableCell >Session {i + 1}</TableCell>
                    <TableCell >{therapy.dates_visited[i] ? getFormatedCompletionDate(therapy.dates_visited[i]) : <Button value={therapy._id} onClick={markAsDone}>Mark as completed</Button>}</TableCell>
                </TableRow>


            );
        }

        return elements;
    }

    return (
        <div>
            <div className="appbar">

                <AppBar sx={{ position: 'relative', background: colorConfigs.topbar.bg, }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Patient Therapy details
                        </Typography>
                        <Tooltip title="Add new Therapy">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={Print}
                                aria-label="close"
                            >
                                <AddIcon />

                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
            </div>
            <div >
                <div style={{ margin: 'auto', display: 'table', padding: '10px', }} >
                    <img src={assets.images.logo} style={{ width: "250px" }} />
                </div >
                <div style={{ margin: 'auto', display: 'table', padding: '10px', width: '60%' }}>
                    <TableContainer>
                        <Table aria-label="spanning table">
                            <TableBody sx={trtd}>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd} colSpan={2}><b>Patient Details</b></TableCell>
                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={1} >Name: {name}</TableCell>
                                    <TableCell sx={trtd_left} colSpan={1}>C/O: {guardianName}</TableCell>
                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={1}>Patient Id: {patinetId}</TableCell>

                                    <TableCell sx={trtd_left} colSpan={1} align="right">Sex: {sex}</TableCell>
                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={2}>Address: {address}</TableCell>

                                </TableRow>
                                <TableRow sx={trtd}>
                                    <TableCell sx={trtd_left} colSpan={1} align="right">Contact: {contactNo}</TableCell>
                                    <TableCell sx={trtd_left} colSpan={1} align="right">Email: {emailId}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>

                    </TableContainer>

                    <div style={{ marginTop: '30px' }}>

                        {
                            consultationData && consultationData.map((value: any) => {
                                return (

                                    <Accordion key={value._id} expanded={expanded === value._id} onChange={handleChange(value._id)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}>
                                            <div style={{ width: '100%' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <Typography sx={{ width: '50%', flexShrink: 0 }}>
                                                        Therapy Name: { therapies.get(value.service_id)}
                                                    </Typography>
                                                    <Typography >Purchase Date: {Moment(value.date_time).local().format("DD-MMM-YYYY")}</Typography>
                                                </div>
                                                <div style={{ marginTop: '10px' }}>
                                                    <Typography sx={{ color: 'text.secondary' }}>{value.dates_visited.length} 0f {value.total_sessions} Session(s) taken</Typography>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '0px' }}>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="spanning table">
                                                    <TableBody >
                                                        {createTherapySessions(value)}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Therapy</DialogTitle>
                <DialogContent dividers style={{ padding: "0px" }}>
                    <TherapyForm editData={fnProps.editData} handleClose={closeDialog} openMode={commonConst.MODE_ADD} refreshData={fetchData}></TherapyForm>
                </DialogContent>

            </Dialog>
            <Snackbar open={successOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000} onClose={fetchData}>
                <Alert onClose={handleCloseSnakbar} severity="success" sx={{ width: '100%' }}>
                    Data saved successfully
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
                    Error while saving data
                </Alert>
            </Snackbar>

        </div>
    )
}

export default TherapyDetails
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import { CssTextField } from '../../components/common/customTextFiled';
import { Button } from '@mui/material';
import { btnSx } from '../../configs/colorConfigs';
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import { FuncProps } from '../../components/common/Common';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';

export const ServicesForm = (fnProps: FuncProps) =>{

 const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

    const [name, setName] = useState(fnProps.editData ? fnProps.editData.name: "");
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const [amount, setAmount] = useState(fnProps.editData ? fnProps.editData.amount: "");
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value);
    };

    const [service_type, setServiceType] = useState(fnProps.editData ? fnProps.editData.service_type: "Rehabilitation Services");
    const handleSCTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceType(event.target.value);
    };
    
    const [successOpen, setSuccess] = useState(false);
    const [errorOpen, setError] = useState(false);

    const handleSubmit = (event:React.FormEvent<HTMLFormElement> ) => {
        event.preventDefault()

        const dataObj = {
            name : name,
            amount: amount,
            service_type:service_type,
            _id: ''
        }

        if(fnProps.editData){
            dataObj._id = fnProps.editData._id
        }

        Nw.Post(fnProps.editData ? commonConst.EDIT_SERVICE: commonConst.ADD_SERVICE,dataObj,commonConst.getCommonHeader()).then(response => {

            if(response.data.code === 200){
                setSuccess(true)
            }else{
                setError(true)
            }

        }).catch(error => {

            console.log(error);
            setError(true)
        })
    };

    const handleClose = () =>{
        fnProps.handleClose()
    }

    const handleAPISuccess = () =>{
        fnProps.refreshData()
        fnProps.handleClose()
    }

    const handleCloseSnakbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccess(false)
        setError(false)
      };

    return(
        <div>
             <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={0.5} style={{padding:"24px"}}>
                <Grid item xs={4}>
                <CssTextField required name='name' fullWidth value={name} label="Name" onChange={handleNameChange}/>
                </Grid>
                <Grid item xs={4}>
                <CssTextField required name='amount' type="number" fullWidth value={amount} label="Amount" onChange={handleAmountChange}/>
                </Grid>
                <Grid item xs={4}>
                <CssTextField select label="Service Type" name='service_type' fullWidth defaultValue="Rehabilitation Services" value={service_type} onChange={handleSCTypeChange}>
                <MenuItem value="Rehabilitation Services">
                    Rehabilitation Services
                </MenuItem>
                <MenuItem value="Diagnostic Tests">
                    Diagnostic Tests
                </MenuItem>
                <MenuItem value="Therapy">
                    Therapy
                </MenuItem>
                </CssTextField>
                </Grid>
                
            </Grid>
            <Divider style={{marginTop:'0px'}}/>
            <div style={{padding:'10px',float:'right'}}>
            <Button sx={btnSx} onClick={handleClose} >Cancel</Button>
            <Button sx={btnSx} type='submit'>{fnProps.editData ? "Update": "Save"}</Button>
            </div>
           
            </form>

            <Snackbar open={successOpen} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} autoHideDuration={3000} onClose={handleAPISuccess}>
                <Alert onClose={handleCloseSnakbar} severity="success" sx={{ width: '100%' }}>
                Data saved successfully
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} anchorOrigin={{ vertical:'bottom', horizontal:'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
                Error while saving data
                </Alert>
            </Snackbar>

        </div>
    )
};
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { CssTextField } from '../../components/common/customTextFiled';
import { Button } from '@mui/material';
import colorConfigs, { btnSx } from '../../configs/colorConfigs';
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import { FuncProps } from '../../components/common/Common';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { DBService } from '../../components/common/DBServices';
import '../../styles/styles.css'


export const EquipmentPurchaseForm = (fnProps: FuncProps) => {

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const style = {
        chips: {
          background: colorConfigs.themeColor,
          'height':'40px',
          'margin-bottom': '0px'
        },
        searchBox: {
            'min-height': '50px'
        },
        multiselectContainer: {
            'color': "black"
        },
        option: { // To change css for dropdown options
            color: "black",
          
          },
        
      };

    useEffect(() => {

        const db = new DBService()
        const siteArr: any[] = []
        db.getAll("sites").then(sites => {

            if (sites) {

                for (let index = 0; index < sites.length; index++) {
                    const element = sites[index];
                    if (element.isActive == true) {
                        const data = {
                            _id: element._id,
                            name: element.name
                        }
                        siteArr.push(data)
                    }
                }

                if (fnProps.openMode === commonConst.MODE_ADD)
                    setsiteId(siteArr[0]._id)

                setSites(siteArr)
            }
        })

       
    }, []);

    const [name, setName] = useState(fnProps.editData ? fnProps.editData.name : "");
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const [errorMsg, setErrorMsg] = useState("")

    const [patientId, setPatientId] = useState(fnProps.editData ? fnProps.editData.patient_id : "");
    const handlePatientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPatientId(event.target.value);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            searchPatient()
        }, 1000)

        return () => clearTimeout(timer)
    }, [patientId])

    const searchPatient = () => {

        if (patientId.length < 7) {
            return
        }

        const data = { patient_id: patientId }
        Nw.Post(commonConst.SEARCH_PATIENT, data, commonConst.getCommonHeader()).then(res => {
            if (res.data.code === 200) {
                const rslt = res.data.success
                setName(rslt.name)
            } else {
                setErrorMsg("Error while finding patient against " + patientId)
                setError(true)
                setName("")
            }
        }).catch(error => {

            console.log(error);
            setErrorMsg("Error while finding patient against " + patientId)
            setError(true)
            setName("")
        })
    }

    const [sites, setSites] = React.useState<any[]>([]);

    const [discount, setDiscount] = useState(fnProps.editData ? fnProps.editData.discount : "0");
    const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscount(event.target.value);
    };

    const [price, setPrice] = useState(fnProps.editData ? parseInt(fnProps.editData.price ?fnProps.editData.price: 0) : 0);
    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(parseInt(event.target.value));
    };

    const [equipment_name, setEquipmentName] = useState(fnProps.editData ? fnProps.editData.equipment_name : "");
    const handleEquipmentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEquipmentName(event.target.value);
    };

    const [serial_no, setSerialNo] = useState(fnProps.editData ? fnProps.editData.serial_no : "");
    const hanldeSerialNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSerialNo(event.target.value);
    };

    const [description, setDescription] = useState(fnProps.editData ? fnProps.editData.description : "");
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    };

    const [siteId, setsiteId] = useState(fnProps.editData ? fnProps.editData.visiting_site_id : "");
    const handleSiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setsiteId(event.target.value);
    };
    const [successOpen, setSuccess] = useState(false);
    const [errorOpen, setError] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const dataObj = {
            "patient_id": patientId,
            "visiting_site_id": siteId,
            "equipment_name": equipment_name,
            "price": price,
            "description": description,
            "serial_no": serial_no,
            "discount": discount,
            "_id":""
        }

        if (fnProps.openMode === commonConst.MODE_EDIT) {
            dataObj._id = fnProps.editData._id
        }
        
        Nw.Post(fnProps.openMode === commonConst.MODE_EDIT ? commonConst.EDIT_EQUIPMENT_PURCHASE :commonConst.ADD_EQUIPMENT_PURCHASE, dataObj, commonConst.getCommonHeader()).then(response => {

            if (response.data.code === 200) {
                setSuccess(true)
            } else {
                setErrorMsg("Error while saving data")
                setError(true)
            }

        }).catch(error => {

            console.log(error);
            setErrorMsg("Error while saving data")
            setError(true)
        })
    };

    const handleClose = () => {
        fnProps.handleClose()
    }

    const handleAPISuccess = () => {
        fnProps.refreshData()
        fnProps.handleClose()
    }

    const handleCloseSnakbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccess(false)
        setError(false)
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={1.5} columnSpacing={0.5} style={{ padding: "24px" }}>
                    <Grid item xs={6}>
                        <CssTextField disabled={fnProps.openMode !== commonConst.MODE_ADD} required name='patientId' fullWidth value={patientId} label="Patient Id" onChange={handlePatientIdChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField disabled name='name' fullWidth value={name} label="Name" onChange={handleNameChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField disabled={fnProps.openMode === commonConst.MODE_VIEW} required select label="Visiting Site" name='siteId' fullWidth  value={siteId} onChange={handleSiteChange}>

                            {
                                sites && sites.map((value: any) => {
                                    return (
                                        <MenuItem value={value._id} key={value._id}>{value.name}</MenuItem>
                                    )
                                })
                            }

                        </CssTextField></Grid>
                    <Grid item xs={6}>
                        <CssTextField disabled={fnProps.openMode === commonConst.MODE_VIEW} required name='equipment_name' fullWidth multiline value={equipment_name} label="Equipment Name" onChange={handleEquipmentNameChange} />
                    </Grid>
                   
                    <Grid item xs={6}>
                        <CssTextField disabled={fnProps.openMode === commonConst.MODE_VIEW} required name='price' type="number" fullWidth value={price} label="Cost" onChange={handlePriceChange} />
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField disabled={fnProps.openMode === commonConst.MODE_VIEW} name='discount' type="number" fullWidth value={discount} label="Discount (if any)" onChange={handleDiscountChange} />
                    </Grid>

                    <Grid item xs={6}>
                        <CssTextField disabled={fnProps.openMode === commonConst.MODE_VIEW} name='serial_no' fullWidth value={serial_no} label="Serial No" onChange={hanldeSerialNoChange} />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <CssTextField disabled={fnProps.openMode === commonConst.MODE_VIEW} name='description' fullWidth multiline maxRows={3} value={description} label="Description" onChange={handleDescriptionChange} />
                    </Grid>

                </Grid>
                <Divider style={{ marginTop: '0px' }} />
                <div style={{ padding: '10px', float: 'right' }}>
                <Button sx={btnSx} onClick={handleClose} >Cancel</Button>
                    {
                        fnProps.openMode !== commonConst.MODE_VIEW && (
                            <Button sx={btnSx} hidden type='submit'>{fnProps.openMode === commonConst.MODE_EDIT ? "Update" : "Save"}</Button>
                        )
                    }

                </div>

            </form>

            <Snackbar open={successOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleAPISuccess}>
                <Alert onClose={handleCloseSnakbar} severity="success" sx={{ width: '100%' }}>
                    Data saved successfully
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>

        </div>
    )
};
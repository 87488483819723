import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { FilterType } from 'mui-datatables'
import Moment from 'moment';
import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';

import { UserForm } from './UserForm';


const options = {
    filterType: 'multiselect' as FilterType,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true
};

const addBtnSx = {
    backgroundColor: colorConfigs.themeColor, borderRadius: '25px', float: "right", height: 'fit-content',
    "&:hover": {
        backgroundColor: colorConfigs.themeColor
    }
};

const UserPage = () => {

    const handleEdit = (index: number) => {
        const editData = data[index]
        setEditData(editData)
        isOpenInEditMode(true)
    }


    const handleReset = (index: number) => {

        const editData: any = data[index]
        const res = window.confirm("Are you sure to reset the password of " + editData.name)
        if (res) {
            const data = { _id: editData._id }
            Nw.Post(commonConst.RESET_PASS, data).then(response => {

                if (response.data.code === 200) {

                    const srcData = response.data.success;
                    const temp_token = srcData.temp_token
                    setTempToken(temp_token)
                    openResetDiaolog(true)
                } else {
                    alert("Something went wrong " + response.data.code)
                }

            }).catch(error => {
                alert("Something went wrong")
            })
        }


    }


    const columns = [

        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "email_id",
            label: "Email",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "isActive",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value: any) => {
                    const status = value == true ? "Active" : "InActive"
                    return (
                        status
                    );
                }
            }
        },

        {
            name: "date_of_creation",
            label: "Registration Date",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value: any) => {
                    return (
                        Moment(value).local().format("DD-MMM-YYYY")

                    );
                }
            }
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                sort: false,
                empty: true,

                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    return (
                        <div style={{ marginLeft: '0px' }}>

                            <Tooltip title={"Edit"}>
                                <IconButton onClick={() =>

                                    handleEdit(dataIndex)
                                }>
                                    <EditIcon></EditIcon>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={"Reset Login Token"}>
                                <IconButton onClick={() =>

                                    handleReset(dataIndex)
                                }>
                                    <LockResetIcon></LockResetIcon>
                                </IconButton>
                            </Tooltip>


                        </div>

                    );
                }
            }
        },
    ];


    useEffect(() => {

        fetchData()

    }, []);

    const fetchData = () => {
        Nw.Get(commonConst.GET_ALL_USER, commonConst.getCommonHeader()).then(response => {

            if (response.data.code === 200) {

                const srcData = response.data.success;
                setData(srcData)

            }
        }).catch(error => {

            console.log(error);
        })
    }

    const [openInAddMode, isOpenInAddMode] = useState(false)
    const [openInEditMode, isOpenInEditMode] = useState(false)
    const [data, setData] = useState([])
    const [editData, setEditData] = useState(undefined)
    const [resetMode, openResetDiaolog] = useState(false)
    const [tempToken, setTempToken] = useState("TOKEN")

    const handleClickOpen = () => {
        isOpenInAddMode(true);
    };

    const handleClose = (event: React.MouseEvent, reason: String) => {
        if (reason && reason === "backdropClick")
            return;
        close()
    };

    const close = () => {
        isOpenInAddMode(false);
        isOpenInEditMode(false)
        setEditData(undefined)
        openResetDiaolog(false)
    };

    return (
        <div style={{ display: "grid" }}>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", alignItems: "center" }}>
                <div>
                    <Typography variant='h6'>User List</Typography>
                    <Typography variant='body1'>{data.length} Available User(s)</Typography>
                </div>

                <Button variant="contained" startIcon={<AddIcon />} sx={addBtnSx} onClick={handleClickOpen}>Add new user</Button>

                <Dialog open={openInAddMode || openInEditMode} onClose={handleClose}>
                    <DialogTitle>{openInAddMode ? "Add New Site" : openInEditMode ? "Update Site Details" : ""}</DialogTitle>
                    <DialogContent dividers style={{ padding: "0px" }}>
                        <UserForm handleClose={close} editData={editData} refreshData={fetchData} openMode={editData ? commonConst.MODE_VIEW : commonConst.MODE_ADD} />

                    </DialogContent>

                </Dialog>


                <Dialog
                    open={resetMode}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {"Tempoary Login Token"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            User Password has been reset. Please share the Tempoary Token <b>{tempToken}</b> to the user in order to set his password again. Ask the user to click on Forgot Password Link in the login page to set the new password.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={close} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
            <MUIDataTable
                title={""}
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
};

export default UserPage;
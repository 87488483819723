import React, { useState,useEffect  } from 'react';
import MUIDataTable from 'mui-datatables'
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colorConfigs from "../../configs/colorConfigs";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { FilterType} from 'mui-datatables'

import * as Nw from "../../components/common/NetworkInterface";
import * as commonConst from '../../components/common/Common';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { SiteForm } from './SiteForm';
import { DBService } from '../../components/common/DBServices';


   const options = {
     filterType: 'multiselect' as FilterType,
     selectableRowsHeader: false,
     selectableRowsHideCheckboxes: true
   };

   const addBtnSx = {
    backgroundColor: colorConfigs.themeColor,  borderRadius:'25px', float:"right", height: 'fit-content',
    "&:hover": {
      backgroundColor: colorConfigs.themeColor
    }
  };


const SitePage = () => {
  
  const handleEdit = (index: number) =>{
    const editData = data[index]
    setEditData(editData)
    isOpenInEditMode(true)
  }

  const columns = [
   
    {
      name: "name",
      label: "Name",
      options: {
       filter: false,
       sort: false,
      }
     },
    
    {
     name: "locality",
     label: "Locality",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
      name: "isActive",
      label: "Status",
      options: {
       filter: true,
       sort: false,
       customBodyRender: (value: any) => {
        const status = value == true ? "Active" : "InActive"
        return (
           status
        );
    }
      }
     },
     {
      name: "Action",
      label: "Action",
      options: {
          filter: false,
          sort: false,
          empty: true,

          customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
              return (
                  <div style={{ marginLeft: '0px' }}>
                      
                      <Tooltip title={"Edit"}>
                          <IconButton onClick={() =>
                              
                              handleEdit(dataIndex)
                          }>
                              <EditIcon></EditIcon>
                          </IconButton>
                      </Tooltip>


                  </div>

              );
          }
      }
  },
   ];
   

  useEffect(() => {

    fetchData()

  },[]);

  const fetchData = () => {
    Nw.Get(commonConst.GET_ALL_SITE,commonConst.getCommonHeader()).then(response=>{

      if (response.data.code === 200) {

        const srcData = response.data.success;
        setData(srcData)
        const db = new DBService()

        for (let index = 0; index < srcData.length; index++) {
          const element = srcData[index];
          db.put("sites",element)
        }
        
      }
    }).catch(error => {

      console.log(error);
  })
  }

  const[openInAddMode,isOpenInAddMode] = useState(false)
  const[openInEditMode,isOpenInEditMode] = useState(false)
  const[data, setData] = useState([])
  const[editData, setEditData] = useState(undefined)

  const handleClickOpen = () => {
    isOpenInAddMode(true);
  };

  const handleClose = (event: React.MouseEvent, reason: String) => {
    if (reason && reason === "backdropClick") 
      return;
      close()
  };

  const close = () => {
    isOpenInAddMode(false);
    isOpenInEditMode(false)
    setEditData(undefined)

  };

  return (
    <div style={{display:"grid"}}>

<div style={{display:"flex", justifyContent:"space-between",marginBottom:"10px",alignItems: "center"}}>
    <div>
    <Typography variant='h6'>Site List</Typography>
    <Typography variant='body1'>{data.length} Available Site(s)</Typography>
    </div>
   
<Button variant="contained" startIcon={<AddIcon/>} sx={addBtnSx} onClick={handleClickOpen}>Add new Site</Button>

<Dialog open={openInAddMode || openInEditMode} onClose={handleClose}>
        <DialogTitle>{openInAddMode ? "Add New Site": openInEditMode? "Update Site Details" : ""}</DialogTitle>
        <DialogContent dividers style={{padding: "0px"}}>
          
          <SiteForm handleClose={close} editData={editData} refreshData={fetchData} openMode={openInAddMode ? commonConst.MODE_ADD : commonConst.MODE_EDIT}/>
        </DialogContent>
      
</Dialog>

</div>
        <MUIDataTable
        title={""}
        data={data}
        columns={columns}
        options={options}
        />
    </div>
  );
};

export default SitePage;
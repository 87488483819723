import { colors } from "@mui/material";

const colorConfigs = {

  themeColor:"#049092",

  sidebar: {
    bg: "#0C254A",
    color: "#eeeeee",
    hoverBg: "#0B3456",
    activeBg: "#0B3456",
    logoBg:`
    linear-gradient(
      120deg,
      #0C1F39,
      #1F4782 80%
    )
  `
  },
  topbar: {
    bg: `
    linear-gradient(
      120deg,
      #0C254A,
      #049092 80%
    )
  `,
    color: "#fff",
  },
  mainBg: colors.grey["100"]
};

export const btnSx = {
  color: colorConfigs.themeColor
};

export default colorConfigs;
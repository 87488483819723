import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { Login } from "./pages/login/Login";
import { routes } from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Login />}>
         
        </Route>
        <Route path="/landing" element={<MainLayout />}>
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import axios, { AxiosResponse } from "axios";
import * as commonConst from '../../components/common/Common';


export async function Post(url: string, postData: any, header?: any): Promise<AxiosResponse> {

    try{
        document.body.style.cursor = "wait"
        const token = await readToken()
        let config = {
            headers: {
    
            }
        }
        if (header) {
            header.authorization = token
        } else {
            header = {
                authorization: token
            }
        }
        var header = { ...config.headers, ...header };
        config.headers = header
        const response = await axios.post(url, postData, config)
        console.log(response)
        document.body.style.cursor = "auto"
        return Promise.resolve(response)
    }catch (error: any) {
        if (error.response.status == 403) {
            window.location.replace('/')
        }
        document.body.style.cursor = "auto"
        return Promise.reject(error)
    }
}

export async function Get(url: string, header ?: any): Promise<AxiosResponse> {

    try {
        document.body.style.cursor = "wait"
        const token = await readToken()
        let config = {
            headers: {

            }
        }
        if (header) {
            header.authorization = token
        } else {
            header = {
                authorization: token
            }
        }
        var header = { ...config.headers, ...header };
        config.headers = header
        const response = await axios.get(url, config)
        console.log(response)
        document.body.style.cursor = "auto"
        return Promise.resolve(response)
    } catch (error: any) {

        if (error.response.status == 403) {
            window.location.replace('/')
        }
        document.body.style.cursor = "auto"
        return Promise.reject(error)
    }
}

async function readToken(): Promise<string> {

    const token = commonConst.getCookie("token")
    const expiry = commonConst.getCookie("expiry")

    if (!token || !expiry) {
        const error = {
            response: {
                status: 403,
                msg: "Token expired"
            }
        }
        return Promise.reject(error)
    }

    const currentTime = new Date().getTime() + 60000

    if (parseInt(expiry) <= currentTime) {
        // refresh token
        try {
            let config = {
                headers: {
                    authorization: token
                }
            }
            const response = await axios.get(commonConst.REFRESH_TOKEN, config)
            if (response.data.code === 200) {

                const srcData = response.data.success;
                const newToken = srcData.jwt
                commonConst.setCookie("token", newToken, 2)
                const payload = commonConst.parseJwt(newToken)
                commonConst.setCookie("expiry", (new Date().getTime()+payload.validity), 2)
                return Promise.resolve(newToken)
            }
        } catch (error: any) {

            return Promise.reject(error)
        }

    }

    return Promise.resolve(token)
}
import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import colorConfigs from "../../configs/colorConfigs";

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: colorConfigs.themeColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colorConfigs.themeColor,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: colorConfigs.themeColor,
      },
    },
  });
import React, { useState, useEffect } from 'react';
import colorConfigs from "../../configs/colorConfigs";
import assets from "../../assets";
import Avatar from '@mui/material/Avatar';
import { Box, TextField, Typography, Button, Link, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import axios from 'axios';
import * as commonConst from '../../components/common/Common';
import { useNavigate } from "react-router-dom"
import { sha256 } from 'crypto-hash';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';

export const Login = () => {

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const navigate = useNavigate();
    useEffect(() => {

        const token = commonConst.getCookie("token")
        const expiry = commonConst.getCookie("expiry")
        if (token && expiry) {
            navigate("/landing")
        }

    }, []);

    const [email, setEmail] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [password, setPassword] = useState('')
    const [openForgotPassDialog, isForgotPassDlgOpen] = useState(false)
    const [successOpen, setSuccess] = useState(false);
    const [errorOpen, setError] = useState(false);
    const [tempCode, setTempCode] = useState('')
    const [enterPassword, setEnterPassword] = useState('')
    const [reEnterPassword, setReEnterPassword] = useState('')
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const close = () => {
        isForgotPassDlgOpen(false);

    };

    const handleCloseSnakbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccess(false)
        setError(false)
    };

    const resetPassword = async () => {

        if (!enterPassword || !reEnterPassword || !tempCode || !email) {
            alert("Please Enter all fields")
            return
        }

        if (enterPassword !== reEnterPassword) {

            alert("Password not matching")
            return
        }

        const data = {
            email_id: email,
            password: await sha256(enterPassword.trim()),
            temp_token: await sha256(tempCode.trim())
        }
        document.body.style.cursor = "wait"
        axios.post(commonConst.SET_PASS, data).then(response => {
            document.body.style.cursor = "auto"
            if (response.data.code === 200) {
                close()
                setSuccess(true)
            } else {
                setErrorMsg("Something went wrong. Please try again!")
                setError(true)
            }

        }).catch(error => {
            setErrorMsg("Something went wrong. Please try again!")
            setError(true)
            document.body.style.cursor = "auto"
        })

    }

    const openForgetPassDlg = () => {
        isForgotPassDlgOpen(true);
    }

    const handleTempCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempCode(event.target.value);
    };
    const handleEnterPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnterPassword(event.target.value);
    };

    const handleReEnterPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReEnterPassword(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = {
            email_id: email,
            password: await sha256(password)
        }
        document.body.style.cursor = "wait"
        axios.post(commonConst.LOGIN, data).then(response => {
            document.body.style.cursor = "auto"
            if (response.data.code === 200) {

                const srcData = response.data.success;
                commonConst.setCookie("token", srcData.jwt, 2)
                const payload = commonConst.parseJwt(srcData.jwt)
                commonConst.setCookie("expiry", (new Date().getTime() + payload.validity), 2)
                navigate("/landing")
            } else {
                setError(true)
                setErrorMsg(response.data.message)
            }

        }).catch(error => {
            setError(true)
            setErrorMsg("Something went wrong. Please try again!")
            document.body.style.cursor = "auto"
        })
    }
    return (

        <Box sx={{ backgroundColor: colorConfigs.mainBg, height: '100%', width: '100%', position: 'absolute' }}>
            <div style={{ textAlign: 'center' }}>
                <img src={assets.images.logo} style={{ width: "300px", marginTop: '1%' }} />
            </div>
            <div style={{ marginTop: "5%", padding: '30px', width: '30%', textAlign: 'center', left: '35%', position: 'absolute', boxShadow: '1px 2px 9px #0C254A', }}>

                <Avatar style={{ backgroundColor: '#f50057', margin: "auto" }}>
                    <LockPersonIcon />
                </Avatar >
                <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
                    Sign in
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email_id"
                        autoComplete="email"
                        value={email}
                        autoFocus
                        onChange={handleEmailChange}
                    />
                    <FormControl sx={{ m: 1, width: '100%', margin: '0' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            required
                            fullWidth
                            onChange={handlePasswordChange}
                            autoComplete="current-password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        /></FormControl>

                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "15px" }}

                        >

                            Submit
                        </Button>
                    </div>
                    <div style={{ marginTop: "15px", float: "right" }}>
                        <Link onClick={openForgetPassDlg} href="#"  >
                            Forgot password?
                        </Link>
                    </div>
                </form>
            </div>

            <Dialog open={openForgotPassDialog}>
                <DialogTitle>Set Password</DialogTitle>
                <DialogContent>
                    <Box>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email_id"
                            autoComplete="email"
                            value={email}
                            autoFocus
                            onChange={handleEmailChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="temp_code"
                            label="Tempoary Login Token"
                            name="temp_code"
                            value={tempCode}
                            autoFocus
                            onChange={handleTempCodeChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="enter_password"
                            label="Enter Password"
                            type="password"
                            id="enter_password"
                            value={enterPassword}
                            onChange={handleEnterPasswordChange}
                        />

                        <FormControl sx={{ m: 1, width: '100%', marginTop: '10px', marginInlineStart:'0' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Re enter Password</InputLabel>
                            <OutlinedInput
                                id="reenter_password"
                                type={showPassword ? 'text' : 'password'}
                                value={reEnterPassword}
                                required
                                fullWidth
                                onChange={handleReEnterPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Re enter Password"
                            /></FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={resetPassword} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={successOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="success" sx={{ width: '100%' }}>
                    Password created successfully. Please login now!
                </Alert>
            </Snackbar>

            <Snackbar open={errorOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSnakbar}>
                <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
        </Box>
    )


}